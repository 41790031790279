<template>
    <vs-table class="border">
        <template #thead>
            <vs-tr>
                <vs-th>Tài khoản</vs-th>
                <vs-th>Tên người dùng</vs-th>
                <vs-th>Đánh giá</vs-th>
                <vs-th>Phản hồi</vs-th>
                <vs-th>Dịch vụ</vs-th>
                <vs-th>Thời gian đánh giá</vs-th>
            </vs-tr>
        </template>
        <template #tbody>
            <vs-tr v-for="(rating, index) in data" :key="index">
                <vs-td>{{ rating.username }}</vs-td>
                <vs-td>{{ rating.fullname }}</vs-td>
                <vs-td>{{ rating.rating }}</vs-td>
                <vs-td>{{ rating.feedback }}</vs-td>
                <vs-td>
                    <span class="p-1 inline-block text-white shadow-sm bg-blue-500 rounded m-1" v-for="(service, j) in rating.services" :key="j">
                        {{ service }}
                    </span>
                </vs-td>
                <vs-td>{{ moment(rating.updated_at).format('L LTS') }}</vs-td>
            </vs-tr>
        </template>
    </vs-table>
</template>
<script>
export default {
    props: ['data'],
    data() {
        return {};
    },
};
</script>