<template>
    <div class="w-full">
        <ratings-container />
    </div>
</template>
<script>
import RatingsContainer from '../../components/containers/rating/RatingsContainer.vue';
export default {
    components: { RatingsContainer },
    data() {
        return {};
    },
};
</script>