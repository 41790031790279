<template>
    <div class="w-full">
        <ratings 
            :data="$store.state.ratings.data"
        />
        <vs-pagination class="mt-10" v-model="page" :length="$store.state.ratings.total" />
    </div>
</template>

<script>
import Ratings from "../../originals/rating/Ratings.vue";
export default {
    components: { Ratings },
    data() {
        return {
            page: 1,
        };
    },
    watch: {
        page(page) {
            this.$store.dispatch("ratings/fetchRatings", { page });
        },
    },
    beforeMount() {
        this.$store.dispatch("ratings/fetchRatings");
    },
};
</script>